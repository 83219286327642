<template>
  <div id="ServicesAvailable" :class="{'isEn':lang == 'en'}">
    <el-row>
    <el-col>
        <div class="bgimg"><img src="http://cdn.valuetech.com.cn/images/%E5%A4%B4%E5%9B%BE/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE.jpg"></div>
    </el-col>
    </el-row>
 
   <div v-if="eq != 'm'">
    <el-tabs v-model="activeName" type="card" @tab-click="handlePcClick">
      <el-tab-pane v-for="(t,index) in  $t('serveProMsg')" :key="index" :label="t.title" :name="index" >
        <tab-page :key="index" :cmsgList="$t('serveProMsg')[index].other" :showTwoBtn="false"></tab-page>
      </el-tab-pane>
    </el-tabs>
   </div>
   <div v-else>
      <van-tabs v-model="activeName" :ine-width="0" :line-height="0" @click="handleMClick">
        <van-tab v-for="(t,index) in  $t('serveProMsg')" :key="index" :title="t.title" :name="index">
            <tab-page :key="index" :cmsgList="$t('serveProMsg')[index].other" :showTwoBtn="false"></tab-page>
        </van-tab>
      </van-tabs>
   </div>

  </div>
</template>
<script>
import TabPage from "./c-components/TabPage.vue";
export default {
  name: "ServicesAvailable",
  components: {
    TabPage,
  },
  data() {
    return {
      activeName: 0,
      tabList: [
        { name: "first", title: "数字化基础建设" },
        { name: "second", title: "数字化展厅" },
        { name: "third", title: "会员体系建设" },
        { name: "fourth", title: "车联网" },
        { name: "fifth", title: "数字化营销" },
        { name: "sixth", title: "中台建设" },
        { name: "seventh", title: "AI客服中心" },
      ],
    };
  },
  computed: {
    eq() {
      return this.$store.state.eq;
    },
    lang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    handlePcClick(tab, event) {
      this.$nextTick(() => {
        const tabItem = document.getElementsByClassName("el-tabs__item");
        tabItem.forEach((dom, index) => {
          if (dom.classList.contains("is-active")) {
            tabItem[index - 1].classList.add("bnone");
          }
        });
      });
    },
    handleMClick(tab, event) {
      this.$nextTick(() => {
        const tabItem = document.getElementsByClassName("van-tab");
        tabItem.forEach((dom, index) => {
          tabItem[index].classList.remove("bnone");
          if (dom.classList.contains("van-tab--active")) {
            tabItem[index - 1].classList.add("bnone");
            return;
          }
        });
      });
    },
  },
  mounted() {
    //首页跳转过来时消除左侧竖线
    this.handlePcClick();
    this.handleMClick();

    if (this.$route.params.index) {
      this.activeName = this.$route.params.index;
    }
  },
};
</script>
<style lang="scss">
.bgimg {
  img {
    object-fit: cover;
  }
}

.isEn {
  .el-tabs__item {
    font-size: 12px;
  }
}
//pc
.el-tabs__active-bar {
  background-color: transparent !important;
}
.el-tabs__nav-scroll {
  height: 50px;
}
.el-tabs__nav-wrap {
  background-color: rgb(235, 231, 232);
  .el-tabs__nav {
    left: 17%;
    .el-tabs__active-bar {
      opacity: 0;
    }
    .el-tabs__item {
      top: -2px;
      width: 145px;
      height: 50px;
      line-height: 50px;
      border: 0;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      background-color: rgb(235, 231, 232);
      color: rgb(0, 0, 0);
    }
    .el-tabs__item:after {
      content: "";
      position: absolute;
      top: 15px;
      right: 0px;
      width: 2px;
      height: 20px;
      border-left: 2px solid rgba(137, 137, 137, 0.7);
    }
    .el-tabs__item:nth-last-child(1):after {
      border-left: 0px;
    }
    .is-active {
      background-color: rgb(137, 137, 137);
      color: #fff;
    }
    .bnone:after {
      content: "";
      border-left: 0px solid rgba($color: #000000, $alpha: 0);
    }
  }
}
@media screen and (min-width: 1500px) {
  .el-tabs__item {
    margin: 0;
  }
}
@media screen and (min-width: 1900px) {
  .el-tabs__nav {
    left: 18% !important;
    color: red;
    .el-tabs__item {
      width: 175px !important;
      margin: 0;
      padding: 0 20px;
    }
  }
}
@media screen and(max-width:1200px) {
  #CaseShows {
    min-height: 700px;
  }
  .bgimg {
    margin-top: 50px !important;
    height: 150px;
    img {
      object-fit: cover;
    }
  }
  .el-tabs__nav-wrap {
    padding: 0 !important;
  }
  .el-tabs__nav {
    font-size: 12px;
    padding-right: 50px;
    .el-tabs__item {
      width: 90px;
      height: 12px;
      margin: 0px;
      padding: 0 3px;
      font-size: 12px;
    }
  }
  .el-tabs__nav-scroll {
    width: auto;
  }
  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    width: 0px;
    height: 48px;
    font-size: 0px;
  }
}

//mobile
.van-tabs__nav--complete {
  padding: 0;
}
.van-tabs__content {
  margin-top: 15px;
}
.van-tabs--line .van-tabs__wrap {
  height: 50px;
}

.van-tabs__wrap {
  background-color: rgb(235, 231, 232);
  height: 50px;
  .van-tabs__nav {
    .van-tab {
      top: -2px;
      width: 165px;
      height: 52px;
      line-height: 50px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: rgb(235, 231, 232);
      color: rgb(0, 0, 0);
    }
    .van-tab:after {
      content: "";
      position: absolute;
      top: 15px;
      right: 0px;
      margin-left: 20px;
      width: 2px;
      height: 20px;
      border-left: 2px solid rgba(137, 137, 137, 0.7);
    }
    .van-tab:nth-last-child(2):after {
      border-left: 0px;
    }
    .van-tab--active {
      background-color: rgb(137, 137, 137);
      color: #fff;
    }
    .bnone:after {
      content: "";
      border-left: 0px solid rgba($color: #000000, $alpha: 0);
    }
  }
}

@media screen and (min-width: 1500px) {
  .van-tab {
    margin: 0;
    // margin: 0 21px;
  }
}
@media screen and (min-width: 1900px) {
  .van-tabs__nav {
    left: 18% !important;
    .van-tab {
      width: 187px !important;
      margin: 0;
      padding: 0 25px;
    }
  }
}

@media screen and(max-width:1200px) {
  #ServicesAvailable {
    min-height: 700px;
  }
  .bgimg {
    margin-top: 50px !important;
    height: 170px;
  }
  .van-tabs__wrap {
    padding: 0 !important;
  }
  .van-tabs__nav {
    font-size: 12px;
    .van-tab {
      text-align: center;
      width: 110px;
      height: 51px !important;
      line-height: 51px !important;
      margin: 0;
      padding: 0 25px;
      font-size: 12px;
      span {
        overflow: hidden;
      }
    }
  }
}
</style>
