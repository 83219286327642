<template>
    <div>
      <el-row type="flex" justify="center" :class="{'iseven': index%2 == 1}" :gutter="0" v-for="(m,index) in this.cmsgList" v-if="m.title" :key="index">
        <el-col :xs="24" :sm="16" :md="16" :lg="15.5" :xl="15">
          <table class="tableItem">
              <tr v-if="index%2 == 0">
                <td>
                  <img v-if="index%2 == 0" class="evenPR" :src="m.imgUrl" alt="">
                </td>
                <td valign="top">
                  <h1 class="text_l2">{{m.title}}</h1>
                  <p>{{m.itemMsg}}</p>
                  <!-- <a href="javascript:;" v-if="showTwoBtn" >{{$t('xzcy')}}</a> -->
                  <a href="javascript:;" v-if="false" >{{$t('xzcy')}}</a>
                  <a href="javascript:;" v-if="false">{{$t('ljxq')}}</a>
                </td>
              </tr>
              <tr v-else>
                <td valign="top">
                  <h1 class="text_l2">{{m.title}}</h1>
                  <p>{{m.itemMsg}}</p>
                  <!-- <a href="javascript:;" v-if="showTwoBtn" >{{$t('xzcy')}}</a> -->
                  <a href="javascript:;" v-if="false" >{{$t('xzcy')}}</a>
                  <a href="javascript:;" v-if="false">{{$t('ljxq')}}</a>
                </td>
                <td>
                  <img v-if="index%2 == 1" class="evenPL" :src="m.imgUrl" alt="">
                </td>
              </tr>

            </table>
        </el-col>
      </el-row>
      
    </div>
</template>

<script>
export default {
  name: "TabPage",
  props: ["cmsgList", "showTwoBtn"],
  data() {
    return {};
  },
};
</script>
<style lang="scss">
@import "assets/scss/mixin.scss";
@import "assets/scss/global.scss";

.tableItem tr {
  a {
    display: inline-block;
    width: 120px;
    height: 39px;
    border: 1px solid #3164d6;
    border-radius: 5px;
    color: #3164d6;
    background: #fff;
    font-size: 19px;
    font-family: Microsoft YaHei;
    text-align: center;
    text-decoration: none;
    padding-top: 2px;
    margin: 20px 25px 50px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and(min-width:1201px) {
  .tableItem tr td {
    padding: 50px 25px;
    img {
      // width: 500px;
      // height: 303px;
      margin: 30px 0;
      border-radius: 5px;
      object-fit: fill;
      box-shadow: 0px 1px 7px 0px rgba(181, 181, 181, 0.35);
    }
    h1 {
      font-weight: bold;
      margin: 30px 9px;
      padding: 0;
      font-size: 33px;
    }
    p {
      height: 273px;
      margin: 16px 9px 0 9px;
      font-size: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .iseven {
    background-color: #fafafa;
  }
  .tableItem tr td:nth-child(1) {
    width: 50%;
  }
  .tableItem tr td:nth-child(2) {
    width: 50%;
  }
}

@media screen and(max-width:1200px) {
  .tableItem tr td {
    padding: 10px;
    img {
      width: 100%;
      height: 100px;
      margin: 12px 0 12px 0;
      border-radius: 5px;
      object-fit: fill;
      box-shadow: 0px 1px 7px 0px rgba(181, 181, 181, 0.35);
    }
    h1 {
      font-weight: bold;
      height: 18px;
      line-height: 18px;
      margin: 12px 9px 0 9px;
      padding: 0;
      font-size: 12px;
    }
    p {
      height: 68px;
      margin: 16px 9px 0 9px;
      padding: 0 10px 0 0;
      font-size: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow-y: scroll;
    }
    a {
      margin: 12px 3px 0 3px;
      width: 60px;
      height: 20px;
      font-size: 12px;
      padding: 0 0 3px 0;
    }
  }
  .iseven {
    background-color: #fafafa;
  }
  .tableItem tr td:nth-child(1) {
    width: 50%;
  }
  .tableItem tr td:nth-child(2) {
    width: 50%;
  }
}
</style>
